<template>
  <div class="inout-detail">
    <div class="inout-detail-main">
      <park-header></park-header>
      <go-back-title :title="'进出场详情'"></go-back-title>
      <template v-if="detail.in_img">
        <div class="pic-title">入场图片</div>
        <div class="inout-pic-file">
          <div class="inout-pic-file-wrap" >
            <img :src="detail.in_img" alt="入场图片">
          </div>
        </div>
      </template>
      <template  v-if="detail.out_img">
        <div class="pic-title">出场图片</div>
        <div class="inout-pic-file">
          <div class="inout-pic-file-wrap" >
            <img :src="detail.out_img" alt="出场图片">
          </div>
        </div>
      </template>

      <van-cell title-style="text-align: left" title="入场时间"
                v-if="detail.in_time"
                :value="detail.in_time | getDate" size="large" />
      <van-cell title-style="text-align: left" title="出场时间"
                v-if="detail.out_time"
                :value="detail.out_time | getDate" size="large" />
      <van-cell title-style="text-align: left" title="车牌号码"
                :value="detail.vh_p_number" size="large" />
      <van-cell title-style="text-align: left" title="金额减免优惠券"
                :value="detail.discount | filterFee" size="large" />
      <van-cell title-style="text-align: left" title="支付金额"
                :value="detail.paidfee | filterFee" size="large" />
      <!--<van-cell title-style="text-align: left" title="付款方式"-->
                <!--:value="detail.time" size="large" />-->
    </div>

  </div>
</template>

<script>
import parkHeader from '../../public/park-header'
import * as common from '../../api/common'
import * as api from '../../api/api'
import * as mqtt from '../../api/wsconnect.js'
// import * as util from '../../api/util.js'
import { mapState } from 'vuex'
export default {
  name: 'inout-detail',
  components: {
    parkHeader
  },
  data () {
    return {
      detail: {
        img: ''
      }
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    })
  },
  methods: {
    async initList() {
      try {
        this.$toast.loading('正在请求中...')
        let topic = `${mqtt.topicPrefix}${localStorage.getItem('sid')}/${mqtt.topics.mq_query_inout_detail}`;

        await mqtt.subscribe(topic)
        this.isLoading = true
        mqtt.Client.config[mqtt.topics.mq_query_inout_detail] = this.getDetail
        let para = {
          sid: localStorage.getItem('sid'),
          in_out_id: common.getProp('in_out_id')
        }
        let res = await api.axiosRequest(api.urlList.fenglin.query.query_inout_detail, para)
        this.$toast.clear()
        this.isLoading = false
        if (res.code != common.resCode.OK) return this.$toast(res.msg || '请求出错')
      } catch (e) {
        common.showErrorMessage(this, e)
      }
    },
    async getDetail (msg) {
      console.log(msg)
      if (msg.code == common.resCode.OK) {
          this.detail = msg
        if (msg.direction == 1) {
          this.detail.in_img = msg.img
          this.detail.out_img = ''
        } else {
          this.detail.out_img = msg.img
          this.detail.in_img = msg.in_img
        }
      } else {
         this.$toast('请求失败')
      }
    }
  },
  filters: {
    getDate (time) {
      if (time) {
        return common.fmtDate(time, 'yyyy年 MM月dd日 hh:mm:ss')
      } else {
        return ''
      }
    },
    filterFee (fee) {
      if (!fee || fee <= 0) {
        return 0 + '元'
      }
      return (fee / 100).toFixed(2) + '元'
    }
  },
  mounted () {
    new mqtt.Client()
    this.initList().catch(e => console.log(e))
  },
  beforeDestroy () {
    let topic = `${mqtt.topicPrefix}${localStorage.getItem('sid')}/${mqtt.topics.mq_query_inout_detail}`
    mqtt.unsubscribe(topic).catch(e => console.log(e))
    delete mqtt.Client.config[mqtt.topics.mq_query_inout_detail]
  }
}
</script>

<style scoped lang="scss">
@import "../../theme/index";
.inout-detail {
  display: flex;
  @extend .w-100-h-100;
  /*<!--background-color: $global-theme-bg-blue;;-->*/
  &-main {
    position: relative;
    @extend .content-wrap-gap;
    width: 100%;
    .pic-title{
      margin: .8rem 0;
      font-weight: 600;
    }
    .inout-pic-file{
      display: flex;
      width: 100%;
      height: 17.2rem;
      .inout-pic-file-wrap{
        box-sizing: border-box;
        margin: 0 2rem;
        & > img{
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>
